/**
 * Quote Block 3
 */
(function ($) {
    $('.quote-block-3-carousel.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        animateOut: 'fadeOut',
        nav: false,
        dots: false

    });
})(jQuery);
