/**
 * Fixed Header
 */
var fixedHeader = document.querySelector('.site-header.fixed');
if(fixedHeader) {
    var headerHeight = fixedHeader.offsetHeight;
    document.querySelector('main').style.marginTop = headerHeight + 'px';
    console.log(headerHeight);
    window.addEventListener("resize", function() {
        var headerHeight = fixedHeader.offsetHeight;
        console.log(headerHeight);
        document.querySelector('main').style.marginTop = headerHeight + 'px';
    });
}